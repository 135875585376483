import React from 'react';
import { openPopupWidget } from "react-calendly";

const CalendlyButton = ({ url, prefill, pageSettings, utm, label, btnClass }) => {
    const onClick = () => openPopupWidget({ url, prefill, pageSettings, utm });
    return <button onClick={onClick} className={btnClass}>{label}</button>;
};

const CalendlyButtonLink = (props) => {
    return (
        <CalendlyButton
            label={props.label}
            url={props.url}
            btnClass={props.btnClass}
        />
    );
}

// Specifies the default values for props:
CalendlyButtonLink.defaultProps = {
    label: 'Book a Call'
}

export default CalendlyButtonLink;